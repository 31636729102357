import { GetAppManifestFn } from '@wix/yoshi-flow-editor';
import myRewardsComponent from './components/MyRewards/.component.json';

export const getAppManifest: GetAppManifestFn = ({ appManifestBuilder }) => {
  appManifestBuilder.configureWidget(myRewardsComponent.id, (widgetBuilder) => {
    widgetBuilder.gfpp().set('help', { id: '73f5bcd0-3066-4412-b0bc-ddfd9121e442' });
  });

  return appManifestBuilder.build();
};
